<template>
  <open-in-brower-layout :show="openInBrower">
    <div class="pay">
      <div style="margin-bottom: 10px;">
        <span style="color: #f00;">*充值成功后请下线重新上线~</span>
      </div>

      <!-- <p style="margin: 10px 0;color: #999;text-decoration: underline;">
        <router-link :to="{ name: 'Gl' }"
          ><span>* 第一天充值500元的号，如何快速到达70级</span></router-link
        >
      </p> -->

      <van-form class="game-form">
        <van-field
          readonly
          clickable
          name="serverid"
          :value="servername"
          label="区服"
          placeholder="请选择区服"
          @click="serverChoose = true"
        ></van-field>
        <van-popup v-model="serverChoose" position="bottom">
          <van-picker
            show-toolbar
            :columns="serverList"
            value-key="sName"
            @confirm="serverChoosed"
            @cancel="serverChoose = false"
          />
        </van-popup>
        <van-field
          v-model="account"
          name="account"
          label="账号"
          placeholder="请输入账号"
          :rules="[{ required: true, message: '请输入账号' }]"
          @change="accountChangeHandler"
          clearable
        ></van-field>
        <van-field
          readonly
          clickable
          name="charguidl"
          :value="charname"
          placeholder="请选择角色"
          label="角色"
          @click="roleChooseHandler"
        ></van-field>
        <van-popup v-model="roleChoose" position="bottom">
          <van-picker
            :loading="roleLoading"
            show-toolbar
            :columns="roleList"
            value-key="name"
            @confirm="roleChoosed"
            @cancel="roleChoose = false"
          />
        </van-popup>
      </van-form>

      <div
        style="padding: 10px; text-align: center;text-decoration: underline;"
      >
        <router-link :to="{ name: 'OrderList' }">
          <span>去查询订单</span>
        </router-link>
      </div>

      <div class="ka">
        <div class="title">
          <span>活动推荐</span>
          <span class="font-red" style="font-size: 14px;margin-left: 10px;"
            >(推荐)</span
          >
          <!--
          <router-link :to="{ name: 'TlbbReward' }">
            <span
              style="font-size: 14px; text-decoration: underline; color: blue; margin-left: 10px;"
              >领取卡类奖励</span
            >
          </router-link>
          -->
        </div>
        <!-- 
        <div style="margin-bottom: 20px;font-weight: bold">
          <p class="font-red">
            需要手动领取活动卡！！！充值成功后请点击上方按钮前往领取~
          </p>
        </div>
        -->
        <div class="prods-ka flex">
          <div
            class="ka-item"
            v-for="hd in hdList"
            :key="hd.ID"
            :class="{
              'active-btn': activeBtnId === hd.ID && activaBtnType === 'hd'
            }"
          >
            <div @click="chooseHd(hd.ID)" class="flex justify-between">
              <span>{{ hd.productName }}</span>
              <span class="font-red">{{ `￥${hd.price / 100}` }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 
      <div
        style="padding: 10px; text-align: center;text-decoration: underline;color: blue"
      >
        <span @click="goReward">去领取活动卡类奖励</span>
      </div>
       -->

      <div class="money">
        <div class="title">
          <span>游戏币充值</span>
          <router-link :to="{ name: 'PayRewardDesc' }">
            <span
              style="font-size: 14px; text-decoration: underline; color: blue; margin-left: 10px;"
              >充值奖励说明</span
            >
          </router-link>
        </div>
        <div class="prods-money flex">
          <div v-for="prod in moneyList" :key="prod.ID" class="prod-item">
            <div
              class="prod-item-content flex justify-center align-center"
              :class="{
                'active-btn':
                  activeBtnId == prod.ID && activaBtnType === 'money'
              }"
              @click="chooseProd(prod.ID)"
            >
              {{ prod.productName }}
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="ka">
        <div class="title">
          <span>卡类充值</span>
          <span class="font-red" style="font-size: 14px;margin-left: 10px;"
            >(不推荐)</span
          >
          <router-link :to="{ name: 'TlbbReward' }">
            <span
              style="margin-left: 10px;font-size: 14px;color: blue;text-decoration: underline;"
              >去领取卡类奖励</span
            >
          </router-link>
        </div>
        <div style="margin-bottom: 20px;font-weight: bold" class="font-red">
          以下卡类充值没有【不绑定】元宝,不激活游戏内的累计充值奖励！
        </div> -->

      <!-- <div
          style="padding: 10px; text-align: center;text-decoration: underline;color: blue"
        >
          <span @click="goReward">去领取卡类奖励</span>
        </div> -->

      <!-- <div class="prods-ka flex">
          <div
            class="ka-item"
            v-for="ka in kaList"
            :key="ka.ID"
            :class="{ 'active-btn': activeBtnId === ka.ID }"
          >
            <div @click="chooseProd(ka.ID)" class="flex justify-between">
              <span>{{ ka.productName }}</span>
              <span class="font-red">{{ `￥${ka.price / 100}` }}</span>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="ka">
        <div class="title">
          <span>补差额</span>
          <span class="font-red" style="font-size: 14px;margin-left: 10px;"
            >(勿拍)</span
          >
        </div>
        <div style="margin-bottom: 20px;font-weight: bold" class="font-red">
          仅用于和客服商量后补差价所用，切勿私自乱拍！
        </div>

        <div class="prods-ka flex">
          <div
            class="ka-item"
            v-for="ka in buList"
            :key="ka.ID"
            :class="{ 'active-btn': activeBtnId === ka.ID }"
          >
            <div @click="chooseProd(ka.ID)" class="flex justify-between">
              <span>{{ ka.productName }}</span>
              <span class="font-red">{{ `￥${ka.price / 100}` }}</span>
            </div>
          </div>
        </div>
      </div> -->

      <div class="pay-btn-wrap">
        <!-- <van-button type="danger" block round @click="choosePayment"
          >立即充值</van-button
        > -->
        <van-popup v-model="payMethodChooseBox" position="bottom" closeable>
          <div class="pay-method-checkbox">
            <span class="title">支付方式</span>
            <van-radio-group v-model="payMethod" class="pay-checkbox-group">
              <van-radio
                v-for="item in payMethodsList.list"
                :key="item.value"
                :name="item.value"
                class="pay-method-radio"
                :disabled="activeProduct.price > item.max * 100"
              >
                <div class="flex flex-row justify-between">
                  <span>{{ item.name }}</span>
                  <span
                    class="notice"
                    v-if="activeProduct.price > item.max * 100"
                    >不支持超过{{ item.max }}元</span
                  >
                </div>
              </van-radio>
            </van-radio-group>
            <van-button type="danger" block round @click="createOrderHandle"
              >立即支付</van-button
            >
          </div>
        </van-popup>
      </div>

      <van-popup
        v-model="prodDescStatus"
        closeable
        position="bottom"
        class="prod-desc"
      >
        <div>
          <div style="overflow-y: hidden">
            <div class="title">
              <span>{{ activeProduct.productName }}</span>
            </div>
            <div class="price">
              <span style="color: red;">{{
                `￥${(activeProduct.price / 100).toFixed(2)}`
              }}</span>
            </div>
            <div
              style="margin: 20px 0 20px 0; min-height: 100px; max-height: 300px;overflow-y: auto;"
            >
              <span>{{
                activeProduct.description ||
                  "暂时没有商品描述，请联系代理咨询，避免买错！由于是自动充值，付款后不予退款，请谨慎购买！"
              }}</span>
            </div>
          </div>
        </div>
        <van-button type="danger" block round @click="choosePayment"
          >立即充值</van-button
        >
      </van-popup>
    </div>
  </open-in-brower-layout>
</template>
<script>
import {
  Button,
  Form,
  Field,
  Popup,
  Picker,
  Toast,
  Radio,
  RadioGroup
} from "vant";
import { mapActions } from "vuex";
import { isWechat } from "@/utils";
import OpenInBrowerLayout from "@/components/OpenInBrower";

export default {
  name: "TlbbPay",
  components: {
    "van-button": Button,
    "van-form": Form,
    "van-field": Field,
    "van-popup": Popup,
    "van-picker": Picker,
    "van-radio": Radio,
    "van-radio-group": RadioGroup,
    "open-in-brower-layout": OpenInBrowerLayout
  },
  data() {
    return {
      moneyList: [],
      productList: [],
      kaList: [],
      hdList: [],
      buList: [],
      activeBtnId: 0,
      activaBtnType: "money",
      servername: "",
      serverChoose: false,
      account: "",
      serverList: [],
      serverid: 0,
      charname: "",
      roleChoose: false,
      charguidl: 0,
      roleList: [],
      roleLoading: false,
      payMethodChooseBox: false,
      payMethod: "",
      openInBrower: false,
      payMethodsList: {},
      prodDescStatus: false
    };
  },
  computed: {
    activeProduct() {
      let activeList = [];
      switch (this.activaBtnType) {
        case "money":
          activeList = this.productList;
          break;
        case "hd":
          activeList = this.hdList;
          break;
        default:
          Toast.fail({
            message: "选择出错啦！",
            duration: 3000
          });
      }

      let idx = activeList.findIndex(product => {
        return product.ID === this.activeBtnId;
      });
      console.log(idx);
      if (idx >= 0) {
        return activeList[idx];
      } else {
        return {};
      }
    }
  },
  async mounted() {
    let loading = Toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0
    });

    try {
      let account = window.localStorage.getItem("account");
      if (account) {
        this.account = account;
      }

      let payMethodsList = await this.getPayMethod();
      this.payMethodsList = payMethodsList.data;

      let prodsData = await this.getProductList();
      let prodsList = prodsData.data.list;
      this.productList = prodsList;
      this.moneyList = prodsList.filter(prod => {
        return prod.type === "money";
      });
      this.kaList = prodsList.filter(prod => {
        return prod.type === "ka";
      });
      this.hdList = prodsList.filter(prod => {
        return prod.type === "hd";
      });
      this.buList = prodsList.filter(prod => {
        return prod.type === "bu";
      });

      let serverData = await this.getServers();
      this.serverList = serverData.data.list;

      this.$dialog.alert({
        title: "提示",
        message:
          "为避免系统无法及时发放每日充值奖励，每天最后10分钟无法充值，请于每天11:50之前进行充值！若每日奖励没有到账，请于当天联系客服或者自行手动领取！",
        confirmButtonText: "我知道了"
      });
      loading.clear();
    } catch (err) {
      console.log(err);
      loading.clear();
    }
  },
  methods: {
    ...mapActions("reword", ["getServers", "getName", "getProductList"]),
    ...mapActions("order", ["createOrder", "getPayMethod"]),
    ...mapActions("wechat", ["getWechatAppid", "getWechatPayParams"]),
    ...mapActions("alipay", ["getAlipayParams"]),
    ...mapActions("pay", [
      "getLyPayParams",
      "getQyPayParams",
      "getQtPayParams"
    ]),
    chooseProd(id) {
      this.activeBtnId = id;
      this.activaBtnType = "money";
      this.prodDescStatus = true;

      this.payMethod = ""; // 重新选择商品时，将支付方式重置，不然如果之前选择的是规定范围内的金额，后面就可以选择规定范围之外的金额
    },
    chooseHd(id) {
      this.activeBtnId = id;
      this.activaBtnType = "hd";
      this.prodDescStatus = true;
      this.payMethod = ""; // 重新选择商品时，将支付方式重置，不然如果之前选择的是规定范围内的金额，后面就可以选择规定范围之外的金额
    },
    choosePayment() {
      this.prodDescStatus = false;
      this.payMethodChooseBox = true;
      this.defaultPayment();
    },
    defaultPayment() {
      let idx = this.productList.findIndex(product => {
        return product.ID === this.activeBtnId;
      });

      let prod = this.productList[idx];
      if (!prod) {
        return "";
      }
    },
    async createOrderHandle() {
      if (!this.payMethod) {
        Toast.fail({
          message: "请选择支付方式",
          duration: 3000
        });
        return;
      }
      if (!this.account) {
        Toast.fail({
          message: "请填账户",
          duration: 3000
        });
        return;
      }
      if (!this.serverid || !this.servername) {
        Toast.fail({
          message: "请选择区服",
          duration: 3000
        });
        return;
      }
      if (!this.charguidl || !this.charname) {
        Toast.fail({
          message: "请选择角色",
          duration: 3000
        });
        return;
      }
      if (!this.activeBtnId) {
        Toast.fail({
          message: "请选择充值金额",
          duration: 3000
        });
        return;
      }

      let nowHours = new Date().getHours();
      let nowMinutes = new Date().getMinutes();

      if (nowHours >= 23 && nowMinutes >= 50) {
        this.$dialog.alert({
          title: "提示",
          message:
            "为避免系统无法及时发放每日充值奖励，最后10分钟无法充值，请提前进行充值！",
          confirmButtonText: "我知道了"
        });
        return;
      }

      let loading = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });
      try {
        let params = {
          account: this.account,
          charguidl: this.charguidl,
          charname: this.charname,
          productNameID: this.activeBtnId,
          productType: this.activaBtnType,
          serverid: this.serverid,
          servername: this.servername,
          payMethod: this.payMethod
        };
        let orderData = await this.createOrder(params);
        let orderSn = orderData.data.orderSn;

        window.localStorage.setItem("account", this.account);
        if (this.payMethodsList.payChoose === "custom") {
          if (this.payMethod === "wechat") {
            await this.wechatPay(orderSn);
          }

          if (this.payMethod === "alipay") {
            await this.alipay(orderSn);
          }
        }
        if (this.payMethodsList.payChoose === "lyPay") {
          await this.lyPay(orderSn);
        }

        if (this.payMethodsList.payChoose === "qyPay") {
          await this.qyPay(orderSn);
        }

        if (this.payMethodsList.payChoose === "ptPay") {
          await this.qtPay(orderSn);
        }

        loading.clear();
      } catch (err) {
        console.log(err);
        loading.clear();
      }
    },
    async wechatPay(orderSn) {
      if (isWechat()) {
        const domain = window.location.hostname;
        const protocol = window.location.protocol + "//";
        const appid = (await this.getWechatAppid()).data.appid;
        const payUrl = encodeURIComponent(
          protocol + domain + "/tlbb/pay/result"
        );
        const redirectUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${payUrl}&response_type=code&scope=snsapi_base&state=${orderSn}#wechat_redirect`;
        window.location.href = redirectUrl;
      } else {
        // 如果是在浏览器，需要获取到支付跳转地址，然后跳转
        const res = await this.getWechatPayParams({
          orderSn,
          type: "MWEB"
        });
        const mwebUrl = res.data.mwebUrl;

        window.location.href = mwebUrl;
      }
    },
    async alipay(orderSn) {
      if (isWechat()) {
        this.openInBrower = true;
        return;
      }
      const res = await this.getAlipayParams({
        orderSn
      });
      const alipayUrl = res.data;
      window.location.href = alipayUrl;
    },
    async lyPay(orderSn) {
      let { data } = await this.getLyPayParams({
        orderSn,
        payment: this.payMethod
      });
      window.location.href = data;
    },
    async qyPay(orderSn) {
      let { data } = await this.getQyPayParams({
        orderSn,
        payment: this.payMethod
      });
      window.location.href = data;
    },
    async qtPay(orderSn) {
      let { data } = await this.getQtPayParams({
        orderSn,
        payment: this.payMethod
      });
      window.location.href = data;
    },
    accountChangeHandler() {
      this.dataInit();
    },
    roleChooseHandler() {
      this.roleLoading = true;
      this.roleChoose = true;
      this.getName({
        serverid: this.serverid,
        username: this.account
      })
        .then(({ data }) => {
          this.roleList = data.list;
          this.roleLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.roleLoading = false;
          this.roleChoose = false;
        });
    },
    serverChoosed(value) {
      this.serverid = value.ID;
      this.servername = value.sName;
      this.serverChoose = false;
      this.dataInit();
    },
    roleChoosed(value) {
      this.charguidl = value.gid;
      this.charname = value.name;
      this.roleChoose = false;
    },
    dataInit() {
      this.roleList = [];
      this.charname = "";
      this.charguidl = 0;
    },
    goReward() {
      // window.location.href = 'https://b.tl13.ml/#/breward'
      this.$router.push({
        name: "TlbbReward"
      });
    }
  }
};
</script>
<style lang="less" scoped>
.pay {
  padding: 0 20px 100px 20px;
}

.prods-money {
  flex-wrap: wrap;
  padding-left: 5px;

  .prod-item {
    box-sizing: border-box;
    flex-basis: 33%;
    margin-top: 10px;
    padding-right: 10px;

    .prod-item-content {
      padding: 15px 0;
      box-sizing: border-box;
      height: 100%;
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid #fff;
    }
  }
}

.prod-desc {
  padding: 20px 5%;
}

.title {
  padding: 15px 0 15px 5px;
  font-size: 20px;
  font-weight: bold;
}

.ka {
  .prods-ka {
    flex-direction: column;

    .ka-item {
      padding: 15px 12px;
      background-color: #fff;
      margin-bottom: 10px;
      border-radius: 3px;
      border: 1px solid #fff;
    }
  }
}

.active-btn {
  border: 1px solid #ff976a !important;
  background-color: #fff5f0 !important;
  color: #ff976a;
  font-weight: bold;
}

.pay-btn-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  // background-color: #fff;
  width: 100%;
  padding: 10px 20px;
}

.pay-method-checkbox {
  padding: 20px 20px;

  .pay-checkbox-group {
    padding: 20px 0;

    .pay-method-radio {
      margin: 10px 0;
    }

    .notice {
      margin-left: 10px;
      color: #f00;
    }
  }
}
</style>
